import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@mui/material";
import { Image, Opacity, Power } from "@mui/icons-material";
import fondo from "../../assets/fon_3_marcas.png"
import imgmesa from "../../assets/mesa.png"
import imgpower from "../../assets/power.png"
import imghubspot from "../../assets/hubspot.png"
import imgmuc from "../../assets/muc.png"
import imgintra from "../../assets/intra.png"
import imgcotizador from "../../assets/cotizacion-formal.png"
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import imgdigitalizacion from "../../assets/digitalizacion.png"
import imgbuxix from "../../assets/buxis.png"
import imgdevops from "../../assets/devops.png"
import imgelerning from "../../assets/elearning.png"
import landingpage from "../../assets/landingpage.png"
import { styled } from "@mui/system";
import { loginRequest } from "../../authConfig";


export const HomePage: React.FC<{}> = () => {

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    "Mobile",
    "Android",
    "iPhone",
    "iPad",
    "Windows Phone",
  ];
  const checkDeviceType = () => {
    setIsMobile(
      mobileKeywords.some((keyword) => userAgent.includes(keyword))
    );
  };


  useEffect(() => {
    checkDeviceType();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      
    } else {
      setName("");
    }
  }, [account]);

  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      //const tokenGOW = Cookies.set("COOKIE_AUTH");
      // Cookies.set('tokenGOW', resultado.data.token, { expires: 30 });
      console.log('loginRequest==============>>>', loginRequest)
      instance.loginRedirect(loginRequest);
    }
  }

  const isAuthenticated = useIsAuthenticated();
  const imageURL = "https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg";
  const Background = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    //backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: "50%"
    //marginLeft: -900
  });

  type tarjetaType = {
    titulo: string;
    descripcion: string;
    imagen: string;
    link: string;
  };


  const dataTarjetas: tarjetaType[] = [
    {
      titulo: "Crear Tráfico",
      descripcion:
        "Crea negocios en HubSpot de forma facil y rapida desde Satélite.",
      imagen: landingpage,
      link: `/ventas/negocio/crear`,
    },
    {
      titulo: "HubSpot",
      descripcion:
        "Acceso al CRM de la Corporación, registra aquí tus negocios.",
      imagen: imghubspot,
      link: "https://app.hubspot.com/login",
    },
    {
      titulo: "Power BI",
      descripcion:
        "Analiza los datos de la Corporación de forma simple y objetiva.",
      imagen: imgpower,
      link: `https://go.microsoft.com/fwlink/?LinkId=2183346&clcid=0x40a&pbi_source=websignin_uNav`,
    },
    {
      titulo: "Mesa de Servicio",
      descripcion:
        "Reporta tus problemas e incidentes para una solución ágil.",
      imagen: imgmesa,
      link: `https://maulme.freshdesk.com/support/login`,
    },
    {
      titulo: "Intranet",
      descripcion:
        "Podrás encontrar noticias y micrositios de los departamentos que conforman la Corporación.",
      imagen: imgintra,
      link: `https://corporativomep.sharepoint.com/sites/CorporacinPROAUTO2`,
    },

    {
      titulo: "Buxis",
      descripcion:
        "Gestiona tus procesos con Talento Humano y consulta tus roles de pago.",
      imagen: imgbuxix,
      link: `http://buxis.corporacionproauto.com.ec/bxportal/componentes/WEBFORM.HTM?ID=PORTAL`,
    },

    {
      titulo: "Gestor documental",
      descripcion: "Consulta y almacena en el gestor documental todos los documentos de tu negociación. ",
      imagen: imgdigitalizacion,
      link: `https://softexpert.corporacionproauto.com.ec/softexpert/login`,
    },

    {
      titulo: "Elearning",
      descripcion: "Encontrarás instructivos y detalle para todos nuestros procesos de Corporación.",
      imagen: imgelerning,
      link: `https://educa.corporacionproauto.com.ec/moodle/`,
    },

    {
      titulo: "DevOps",
      descripcion: "Gestiona tus actividades con una herramienta enfocada en una metodología ágil.",
      imagen: imgdevops,
      link: `https://dev.azure.com/CORPORACIONPROAUTO/`,
    },

  ];

  const dataTarjetasMobil: tarjetaType[] = [
    {
      titulo: "Cotizador",
      descripcion:
        "Elabora cotizaciones a partir de tus negocios en HubSpot.",
      imagen: imgcotizador,
      link: `/cotizador/negocios`,
    },
    {
      titulo: "Crear Tráfico",
      descripcion:
        "Crea negocios en HubSpot de forma facil y rapida desde Satélite.",
      imagen: landingpage,
      link: `/ventas/negocio/crear`,
    },
    {
      titulo: "HubSpot",
      descripcion:
        "Acceso al CRM de la Corporación, registra aquí tus negocios.",
      imagen: imghubspot,
      link: "https://app.hubspot.com/login",
    },
    {
      titulo: "Power BI",
      descripcion:
        "Analiza los datos de la Corporación de forma simple y objetiva.",
      imagen: imgpower,
      link: `https://go.microsoft.com/fwlink/?LinkId=2183346&clcid=0x40a&pbi_source=websignin_uNav`,
    },
    {
      titulo: "Mesa de Servicio",
      descripcion:
        "Reporta tus problemas e incidentes para una solución ágil.",
      imagen: imgmesa,
      link: `https://maulme.freshdesk.com/support/login`,
    },
    {
      titulo: "Intranet",
      descripcion:
        "Podrás encontrar noticias y micrositios de los departamentos que conforman la Corporación.",
      imagen: imgintra,
      link: `https://corporativomep.sharepoint.com/sites/CorporacinPROAUTO2`,
    },

    {
      titulo: "Buxis",
      descripcion:
        "Gestiona tus procesos con Talento Humano y consulta tus roles de pago.",
      imagen: imgbuxix,
      link: `http://buxis.corporacionproauto.com.ec/bxportal/componentes/WEBFORM.HTM?ID=PORTAL`,
    },

    {
      titulo: "Gestor documental",
      descripcion: "Consulta y almacena en el gestor documental todos los documentos de tu negociación. ",
      imagen: imgdigitalizacion,
      link: `https://softexpert.corporacionproauto.com.ec/softexpert/login`,
    },

    {
      titulo: "Elearning",
      descripcion: "Encontrarás instructivos y detalle para todos nuestros procesos de Corporación.",
      imagen: imgelerning,
      link: `https://educa.corporacionproauto.com.ec/moodle/`,
    },

    {
      titulo: "DevOps",
      descripcion: "Gestiona tus actividades con una herramienta enfocada en una metodología ágil.",
      imagen: imgdevops,
      link: `https://dev.azure.com/CORPORACIONPROAUTO/`,
    },

  ];

  if (isAuthenticated) {
    return (

      <>
        {/* <Background /> */}

        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ minHeight: "90vh" }}
          >
            <Grid item>
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.7)",
                  mt: 1,
                }}
              >


                {isMobile ? <> <Grid sx={{ marginLeft: 1 }}>
                  {dataTarjetasMobil.map((row) => (
                    <Card
                      sx={{
                        width: 240,
                        display: "inline-block",
                        marginRight: 4,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <CardMedia
                        sx={{ height: 140 }}
                        image={row.imagen}
                        title="Corporacion Proauto"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          <strong>{row.titulo}</strong>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ height: 60 }}
                        >
                          {row.descripcion}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button href={row.link} size="small">
                          Abrir
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </Grid></> : <> <Grid sx={{ marginLeft: 1 }}>
                  {dataTarjetas.map((row) => (
                    <Card
                      sx={{
                        width: 240,
                        display: "inline-block",
                        marginRight: 4,
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <CardMedia
                        sx={{ height: 140 }}
                        image={row.imagen}
                        title="Corporacion Proauto"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          <strong>{row.titulo}</strong>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ height: 60 }}
                        >
                          {row.descripcion}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button href={row.link} size="small">
                          Abrir
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </Grid></>}

                <Typography
                  variant="h6"
                  textAlign={"center"}
                  sx={{ mt: 3, mb: 1 }}
                >
                  © Corporación Proauto 2024
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>

      </>
    );
  } else {
    return (
      <>
      
        {isMobile ? <>
        
          <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ minHeight: "90vh" }}
          >
            <Grid item>
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.7)",
                  mt: 1,
                  width: "100%"
                }}
              >
        <Button
          id="iniciar_sesion"
          onClick={() => handleLogin("redirect")} key="loginPopup"
          variant="contained"
          sx={{ width: "80%", ml:"10%" }}
        >
          Iniciar sesión
        </Button>
        </Paper>
        </Grid>
        </Grid>
        </Container>
        </> : <>
        
        </>}
      </>
    );
  }
};
